<template>
    <p>{{ getType }}</p>
</template>

<script>
export default {
     props: ["data", "index", "column"],
     computed:{
            getType(){
                switch(this.data.reference_type){
                    case 1:
                        return "Transaksi Klaim";
                    case 2:
                        return "Penggunaan Kupon";
                    case 3:
                        return "Custom Poin";
                }
            }
        },
}
</script>