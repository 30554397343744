<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ (this.showModalMode == 'V' ? "" : "Ubah" )}} {{ $t("module.history_point") }}</h5>
                </div>
                    <form @submit.prevent="true;">
                        <div class="modal-body">
                            <input
                                type="hidden"
                                class="form-control"
                                v-model.number="transactionPointModel.id"
                            />
                            <div class="row">
                                    <div class="col-md-6 form-group" >
                                        <label for="">{{ $t('member.member_number') }} : </label>
                                        <input v-model="transactionPointModel.member_no"
                                            class="form-control" disabled="disabled"
                                            ref="memberID"
                                            title="Fokuskan kursor sebelum Scan Kode"
                                            v-validate="'required'"
                                            :class="{'is-invalid': errors.has('member_no')}"
                                            name="member_no" :data-vv-as="$t('member.member_number')"
                                            />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('member.member_id') }} :</label>
                                        <input v-model="transactionPointModel.member_id" class="form-control"
                                        :class="{'is-invalid':errors.has('member_id')}"
                                        disabled="disabled" v-validate="'required'"
                                        name="member_id" :data-vv-as="$t('member.member_id')"/>
                                        <div v-show="errors.first('member_id')" class="custom-invalid-feedback" >{{ errors.first('member_id') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('member.name_on_card') }} :</label>
                                        <input v-model="transactionPointModel.name_on_card" class="form-control"
                                        :class="{'is-invalid':errors.has('name_on_card')}"
                                        disabled="disabled"
                                        name="name_on_card" :data-vv-as="$t('member.name_on_card')"/>
                                        <div v-show="errors.first('name_on_card')" class="custom-invalid-feedback" >{{ errors.first('name_on_card') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.transactionDate') }}* :</label>
                                        <date-picker
                                            :changedValue.sync="transactionPointModel.transaction_date"
                                            :id-date-picker="'jadwal_date_start_' + transactionPointModel.id"
                                            :picker-value="transactionPointModel.transaction_date"
                                            :place-holder="$t('common.transactionDate')"
                                            :is-validate="true"
                                            :isDisabled="modeView"
                                            start-date=""
                                            end-date=""
                                            ></date-picker>
                                        <div v-show="errors.first('_____name____')" class="custom-invalid-feedback" >{{ errors.first('_____name____') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.title') }} :</label>
                                        <input v-model="transactionPointModel.title"
                                        v-validate="{max:100 }"
                                        class="form-control"
                                        :class="{'is-invalid':errors.has('title')}"
                                        :disabled="modeView"
                                        name="title" :data-vv-as="$t('common.title')"/>
                                        <div v-show="errors.first('title')" class="custom-invalid-feedback" >{{ errors.first('subtitle') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.subJudul') }} :</label>
                                        <input v-model="transactionPointModel.subtitle"
                                        v-validate="{max:100 }"
                                        class="form-control"
                                        :class="{'is-invalid':errors.has('subtitle')}"
                                        :disabled="modeView"
                                        name="subtitle" :data-vv-as="$t('common.subJudul')"/>
                                        <div v-show="errors.first('subtitle')" class="custom-invalid-feedback" >{{ errors.first('subtitle') }}</div>
                                    </div>


                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.point') }}:</label>
                                        <input :value="claimPointValue" v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('point')}"
                                        disabled="disabled"
                                        name="point" :data-vv-as="$t('common.point')"/>
                                        <div v-show="errors.first('point')" class="custom-invalid-feedback" >{{ errors.first('point') }}</div>
                                    </div>


                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.keterangan') }}:</label>
                                        <input v-model="transactionPointModel.reference_desc" v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('reference_desc')}"
                                        :disabled="modeView"
                                        name="reference_desc" :data-vv-as="$t('common.keterangan')"/>
                                        <div v-show="errors.first('reference_desc')" class="custom-invalid-feedback" >{{ errors.first('reference_desc') }}</div>
                                    </div>


                            </div>

                        </div>

                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-clean btn-bold btn-upper btn-font-md"
                                @click="hideModal()"
                            >
                                {{ $t("button.close") }}
                            </button>
                            <button
                                v-if="!modeView"
                                type="button"
                                @click="submitData"
                                class="btn btn-default btn-bold btn-upper btn-font-md"
                            >
                                {{ $t("button.save") }}
                            </button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</template>
<script>

import TransactionPointModel from "./../../model/transaction-point-model";
import DatePicker from "./../../../components/_general/DatePicker.vue";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import SelectDropdown from "./../../components/base/SelectDropdown";
import { isNullOrUndefined } from "util";
import Photo from "./../../model/photo-model";
import { mapState, mapMutations, mapActions } from "vuex";
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import TransactionPointRepository from '../../repositories/TransactionPointRepository';
import MemberRepository from '../../repositories/MemberRepository';
import BlockUI from "./../../../components/_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode('passive');
export default {
    components:{ SelectDropdown, DatePicker},

    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal,
            shouldRefreshTable: state => state.shouldRefreshTable,
        }),
        claimPointValue(){
            return ( this.transactionPointModel.in_out_type == "2" ? "-": "+" )+''+this.transactionPointModel.point;
        },
        modeView(){
            return this.showModalMode == 'V';
        },
    },
    provide() {
        return { $validator: this.$validator };
    },
    data(){
        return {
            isReadOnly:false,
            scan_code:null,
            attachmentPaths:[],
            transactionPointModel:new TransactionPointModel()
        }
    },

    watch:{

    },
    methods: {
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),

        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
        },
        async update(payload,couponId) {
                var vx = this;
                await TransactionPointRepository.update(payload,couponId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data History Transaksi Poin berhasil diubah',// response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                // blockUI.unblockModal();
                                vx.hideModal();
                                vx.refreshData(true);
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data History Transaksi Poin gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },

        submitData(){
            this.errors.clear();
            this.$validator.validateAll().then(result => {

                if (result) {
                    var vx = this;
                    let instance = new SweetAlert().showConfirm({
                        swalTitle: "Apakah Anda yakin?",
                        swalText: "Perubahan data akan tersimpan.",
                        swalType: "info",
                        onConfirmButton: function() {
                            blockUI.blockModal();
                            vx.update(vx.transactionPointModel, vx.transactionPointModel.id);
                        },
                        onCancelButton: function() {
                            blockUI.unblockModal();
                        }
                    });


                    return;
                }
                else{

                }
            });
        },

        updateData() {
            if (this.dataEdit) {
                let dataEdit = this.dataEdit;
                this.attachmentPaths = dataEdit.attachments;
                dataEdit.attachments = [];
                this.transactionPointModel = Object.assign({}, dataEdit);
            }
        }
    },
    created(){
        if(this.dataEdit){
            // TransactionPointRepository.getById(this.dataEdit.id).then(response=>{

            // })
        }
    },
    mounted() {
        $("#" + this.modalName).modal();
        this.updateData();
    }
}
</script>
