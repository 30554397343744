<template>
    <div>
        <base-header :base-title="$t('module.history_point')"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="$t('module.history_point')"></base-title>
                    <div class="kt-portlet__head-toolbar"></div>
            </div>

            <div class="kt-portlet__body">
                <crud-table
                ref="crudTable"
                :table-options="tableOptions"
                :repository="repository"
                :permission="permission"
                :event="fetch"
                @on-click-view="onClickView"
                @on-click-edit="onClickEdit"
                @on-click-delete="onClickDelete"
                ></crud-table>
            </div>
        </div>
    </div>
    <form-modal v-if="isShowModal" @event="fetch"></form-modal>
</div>
</template>
<script>
    import BaseHeader from "./../../../components/_base/BaseHeader";
    import BaseTitle from "./../../../components/_base/BaseTitle";
    import CheckPermission from "./../../../services/checkPermission.service";
    import CrudTable from "./../base/crud-table/crudTable";
    import FormModal from "./FormModal";
    import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
    import { mapState, mapMutations, mapActions } from "vuex";
    const transactionPointRepository = RepositoryFactory.get("transactionPointRepository");
    const checkPermission = new CheckPermission();
    import ColumnTransactionDate from '@/components/_general/columnTableLoyalty/ColumnTransactionDate.vue'
    import ColumnPoint from '@/components/_general/columnTableLoyalty/ColumnPoint.vue'
    import ColumnTransactionType from '@/components/_general/columnTableLoyalty/ColumnTransactionType.vue'
 

    export default {
        components:{ BaseHeader, BaseTitle, CrudTable, FormModal },
        methods:{
            ...mapMutations({
                setRepository: 'Crud/SET_REPOSITORY',
                refreshData: 'Crud/REFRESH_TABLE',
                showModal:'Crud/SHOW_MODAL',
                hideModal:'Crud/HIDE_MODAL',
                setModal: 'Crud/SET_MODAL',
                setupTable: 'Crud/SETUP_TABLE',
                setEditData: 'Crud/SET_EDIT_DATA',
                setViewData: 'Crud/SET_VIEW_DATA',
            }),
            checkPermission(method) {
                return checkPermission.isCanAccess(method);
            },
            fetch(){
                this.refreshData(true);
            },
            onClickEdit(data) {
                this.$store.commit("Crud/SET_EDIT_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");

            },
            onClickDelete(data) {
                this.$store.dispatch("Crud/deleteById", {
                    deleteRepository: transactionPointRepository.delete,
                    id: data.id,
                    name: data.name_on_card+", "+data.title
                });
            },
            onClickView(data) {
                this.$store.commit("Crud/SET_VIEW_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");
            }
        },
        computed:{
            ...mapState("Crud", {
                shouldRefreshTable: state => state.shouldRefreshTable,
                modalName: state => state.modalName,
                dataEdit: state => state.dataEdit,
                showModalMode: state => state.showModalMode,
                isShowModal: state => state.isShowModal,
            }),
        },

        watch:{
            shouldRefreshTable: function(newValue, oldValue) {
                if (newValue === true) {
                    this.$refs.crudTable.refreshTable();
                }
            }
        },
        data(){
            var vx = this;
            return {
                repository: transactionPointRepository,
                    permission: {
                        view: true,
                        edit:true,
                        delete:true
                    },
                    tableOptions: {
                        name: "CRUD_TABLE",
                        columns: [ 'id','rTransactionType','rTransactionDate','reference_name','name_on_card','title','subtitle','rPoint','reference_desc','actions' ],
                        sortable: ['id','rTransactionType','rTransactionDate','reference_name','name_on_card','title','subtitle','reference_desc','rPoint' ],
                        orderBy: { column: 'id', ascending:false },
                        headings: {
                            id:'ID',
                            rTransactionType: 'Tipe Transaksi',
                            rTransactionDate:this.$t('coupons.transaction_date'),
                            name_on_card:this.$t('member.name_on_card'),
                            title:this.$t('common.title'),
                            subtitle:this.$t('common.subJudul'),
                            rPoint:this.$t('common.point'),
                            reference_name:this.$t('history_point.transaction_type'),
                            reference_desc:this.$t('common.keterangan'),
                        },
                        customColumn: {
                            rCode:'code',
                            'rTransactionType' : 'reference_type',
                            rTransactionDate: 'transaction_date',
                            rPoint: 'point',
                        },
                        columnsFilter: [
                            {key:'id',type:'number'},
                            {key:'rTransactionDate',type:'date'},
                            {key:'reference_name',type:'string'},
                            {key:'name_on_card',type:'string'},
                            {key:'title',type:'string'},
                            {key:'subtitle',type:'string'},
                            {key:'reference_desc',type:'string'},
                            {key:'rPoint',type:'number'}
                        ],
                        customListFilter: {
                            rTransactionType : {
                                repo : 'historyPointTypeRepository',
                                values: [],
                                renderSelect : function(val){
                                    return (val.name) ? val.name : '';
                                },
                                type: 'multiselect',
                            },
                        },
                        templates: {
                            rTransactionDate:ColumnTransactionDate,
                            rTransactionType:ColumnTransactionType,
                            rPoint:ColumnPoint,
                        },

                    }
            }
        },
        created(){
            this.setModal("HistoryPointModal")
            this.setRepository(transactionPointRepository)
        },
    }
</script>
