<template>
    <p :style="getColor">{{ getPoint }}</p>
</template>
<script>
export default {
     props: ["data", "index", "column"],
     computed:{
            getPoint(){
                let xType = this.data.in_out_type == "2" ? -1 : 1;
                let typ = xType * parseInt(this.data.point);
                if(typ==0){
                    return typ;
                }
                return (typ >= 0 ? '+' :'') + typ;
            },
            getColor(){
                let xType = this.data.in_out_type == "2" ? -1 : 1;
                let color = xType * parseInt(this.data.point);
                if(color == 0){
                    return '';
                }
                return ( color >= 0) ?  'color:green;': 'color:red;';
            }
        },
}
</script>